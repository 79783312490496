import React from "react"
import Header from "../components/header"
import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"

export default ({ data,pageContext }) => {
  return (
    <div>
      <Header back="true" taal={pageContext.langKey} />
      <div>
        <div className="textContent">
          {ReactHtmlParser(data.allPagesResults.edges[0].node.content_nl)}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allPagesResults(filter: { pagesId: { eq: 5 } }) {
      edges {
        node {
          pagesId
          label
          content_nl
        }
      }
    }
  }
`
